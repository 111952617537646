import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, fill, ...props }) => {
  return (
    <svg
      fill={fill ? fill : "currentColor"}
      viewBox="0 0 160 160"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {children}
      <path
        className="cls-1"
        fillRule="evenodd"
        d="M80,0A80,80,0,1,1,0,80,80,80,0,0,1,80,0ZM56.61,108.879l0.013,0-4.54,7.955a5.964,5.964,0,0,1-8.2,2.223,6.111,6.111,0,0,1-2.2-8.294l3.344-5.861,0.321-.562a4.93,4.93,0,0,1,4.817-2s6.658,0.731,7.14,4.233a4.132,4.132,0,0,1-.7,2.3h0ZM121,88.56H106.842a2.077,2.077,0,0,1-1.55-.616l-0.01-.018L90.127,61.369l-0.02.013L89.2,60.063c-1.489-2.3-3.855,3.59-3.855,3.59-2.824,6.568.4,14.035,1.525,16.292l21.049,36.885a5.962,5.962,0,0,0,8.2,2.222,6.109,6.109,0,0,0,2.2-8.293l-5.263-9.224c-0.1-.223-0.279-0.83.8-0.832H121a6.072,6.072,0,0,0,0-12.143h0ZM93.5,96.814s0.756,3.888-2.168,3.888H39A6.072,6.072,0,0,1,39,88.56H52.455c2.172-.127,2.687-1.4,2.687-1.4l0.012,0.006L72.717,56.393H72.711a1.37,1.37,0,0,0,.007-1.245l-5.8-10.164a6.11,6.11,0,0,1,2.2-8.294,5.963,5.963,0,0,1,8.2,2.222L80,43.626l2.685-4.706a5.963,5.963,0,0,1,8.2-2.222,6.11,6.11,0,0,1,2.2,8.294L68.643,87.818c-0.107.261-.14,0.67,0.655,0.742H83.906S92.353,88.837,93.5,96.814Z"
      />
    </svg>
  )
})
