import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, fill, ...props }) => {
  return (
    <svg
      fill={fill ? fill : "currentColor"}
      viewBox="0 0 160 160"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {children}
      <path
        className="cls-1"
        fillRule="evenodd"
        d="M80,0A80,80,0,1,1,0,80,80,80,0,0,1,80,0Zm35.449,99.581a47.946,47.946,0,0,1-4.723,8.507,43.255,43.255,0,0,1-6.085,7.366,11.791,11.791,0,0,1-7.82,3.449,19.573,19.573,0,0,1-7.223-1.727,20.688,20.688,0,0,0-7.776-1.722,21.391,21.391,0,0,0-8,1.722A21.461,21.461,0,0,1,66.916,119q-4.009.172-8-3.541a45.4,45.4,0,0,1-6.364-7.627,52.785,52.785,0,0,1-6.73-13.391A49.1,49.1,0,0,1,43,78.478,29.183,29.183,0,0,1,46.82,63.23a22.43,22.43,0,0,1,8-8.108,21.5,21.5,0,0,1,10.818-3.058,25.436,25.436,0,0,1,8.367,1.951c3.45,1.3,5.665,1.955,6.637,1.955,0.726,0,3.187-.769,7.359-2.3a24.286,24.286,0,0,1,10-1.779q11.087,0.9,16.637,8.775-9.916,6.018-9.815,16.841a18.59,18.59,0,0,0,6.09,14,20,20,0,0,0,6.084,4Q116.268,97.628,115.449,99.581ZM98.5,32.759a18.792,18.792,0,0,1-4.8,12.314C89.831,49.6,85.161,52.208,80.1,51.8a13.732,13.732,0,0,1-.1-1.668,19.307,19.307,0,0,1,5.1-12.444,19.626,19.626,0,0,1,6.209-4.67A18.486,18.486,0,0,1,98.405,31a16.061,16.061,0,0,1,.092,1.761h0Z"
      />
    </svg>
  )
})
