import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, fill, ...props }) => {
  return (
    <svg
      fill={fill ? fill : "currentColor"}
      viewBox="0 0 160 160"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {children}
      <path
        className="cls-1"
        fillRule="evenodd"
        d="M80,160a80,80,0,1,1,80-80A80,80,0,0,1,80,160ZM69.893,47.757L38,52.121V78.477h31.9V47.757Zm0,33.97H38v26.357l31.9,4.452V81.727ZM116,41l-42.26,6.153V78.219H116V41Zm0,41.138H73.735v30.9L116,119V82.137Z"
      />
    </svg>
  )
})
