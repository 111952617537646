import { graphql } from "gatsby"
import React, { FC } from "react"
import { Layout } from "src/components/Layout"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import ArrowLink from "src/components/ArrowLink"
import Mac from "src/components/Icons/Mac"
import Windows from "src/components/Icons/Windows"
import Android from "src/components/Icons/Android"
import Ios from "src/components/Icons/Ios"
import CardLink from "src/components/CardLink"
import { usePages } from "src/context/pages"
import Logo from "src/images/structured-data-logo.png"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => {
  const { getPage, currentPath } = usePages()
  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
      className={styles.layout}
    >
      <div className={`content-wrapper ${styles.content}`}>
        <h1 className={styles.title}>{texts.header}</h1>
        <p className={styles.subtitle}>{texts.subheader}</p>
        <div className={styles.apps}>
          <CardLink
            url="https://cdn.getflow.com/darwin_x64/Flow.dmg"
            title={texts.mac.title}
            subtitle={texts.mac.subtitle}
            bigCard={true}
          >
            <Mac fill="url(#badgeGrad)">
              <defs>
                <linearGradient id="badgeGrad" gradientTransform="rotate(90)">
                  <stop offset="0%" stopColor="#00143d" />
                  <stop offset="100%" stopColor="#001b52" />
                </linearGradient>
              </defs>
            </Mac>
          </CardLink>
          <CardLink
            url="https://cdn.getflow.com/win32_ia32/production/release/Flow.zip"
            title={texts.windows.title}
            subtitle={texts.windows.subtitle}
            bigCard={true}
          >
            <Windows fill="url(#badgeGrad)">
              <defs>
                <linearGradient id="badgeGrad" gradientTransform="rotate(90)">
                  <stop offset="0%" stopColor="#00143d" />
                  <stop offset="100%" stopColor="#001b52" />
                </linearGradient>
              </defs>
            </Windows>
          </CardLink>
          <CardLink
            url="https://apps.apple.com/app/flow-tasks/id1434889263"
            title={texts.ios.title}
            subtitle={texts.ios.subtitle}
            bigCard={true}
          >
            <Ios fill="url(#badgeGrad)">
              <defs>
                <linearGradient id="badgeGrad" gradientTransform="rotate(90)">
                  <stop offset="0%" stopColor="#00143d" />
                  <stop offset="100%" stopColor="#001b52" />
                </linearGradient>
              </defs>
            </Ios>
          </CardLink>
          <CardLink
            url="https://play.google.com/store/apps/details?id=com.getflow.tasks"
            title={texts.android.title}
            subtitle={texts.android.subtitle}
            bigCard={true}
          >
            <Android fill="url(#badgeGrad)">
              <defs>
                <linearGradient id="badgeGrad" gradientTransform="rotate(90)">
                  <stop offset="0%" stopColor="#00143d" />
                  <stop offset="100%" stopColor="#001b52" />
                </linearGradient>
              </defs>
            </Android>
          </CardLink>
        </div>
        <ArrowLink
          text={texts.linkText}
          to={getPage("app").url}
          className={styles.link}
        />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        header
        subheader
        mac {
          title
          subtitle
        }
        windows {
          title
          subtitle
        }
        ios {
          title
          subtitle
        }
        android {
          title
          subtitle
        }
        linkText
      }
    }
  }
`

export default Template
