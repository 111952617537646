import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, fill, ...props }) => {
  return (
    <svg
      fill={fill ? fill : "currentColor"}
      viewBox="0 0 160 160"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {children}
      <path
        className="cls-1"
        fillRule="evenodd"
        d="M80,0A80,80,0,1,1,0,80,80,80,0,0,1,80,0ZM54.67,39l37.4,38.352,10.255-10.591L56.99,39.725A4.847,4.847,0,0,0,54.67,39Zm-3.131,1.784A4.512,4.512,0,0,0,51,42.958v74.53a4.129,4.129,0,0,0,.324,1.617L89.59,79.861ZM105.458,68.6L94.5,79.861l10.956,11.2,13.386-7.916a3.56,3.56,0,0,0,.054-6.522C117.757,75.993,109.29,70.9,105.458,68.6ZM92.073,82.369L54.346,121a4.141,4.141,0,0,0,1.943-.558c1.477-.885,31.358-18.73,31.358-18.73l14.735-8.752Z"
      />
    </svg>
  )
})
